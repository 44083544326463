<template>
    <v-container>
        <v-row>
            <v-col>
                <v-list v-if="cart.items">
                    <v-list-item v-for="item in cart.items" :key="item.id">
                        <v-list-item-avatar>
                            <img :src="item.product.image_url"/>
                        </v-list-item-avatar>
                        <v-list-item-title>{{item.product.name}}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-col>
            <v-col>
                Success
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "success",
        computed: {
            ...mapState({
                cart: state => state.cart.cart
            })
        },
        data: function () {
            return {}
        },
        mounted() {
            this.fetchCart()
        },
        methods: {
            async fetchCart() {
                const id = localStorage.getItem('cart_id')
                this.$store.dispatch('cart/fetchCart', {id}).then(res =>{
                    console.log(res)
                })
            }
        }
    }
</script>

<style scoped>

</style>
